import React, { useState, useEffect, useContext } from "react";
import "../style/odds.css";
import axios from "axios";
import Spinner from "./Spinner";
import { useLayoutEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import socketIOClient from "socket.io-client";
import ReplayIcon from "@mui/icons-material/Replay";
import api_url from "../constants";
const ENDPOINT = "https://api2.fly247.in";
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

function AllOdds() {
  const [authContext, setAuthContext] = useContext(AuthContext);
  const config = {
    headers: { Authorization: `Bearer ${authContext.token}` },
  };
  const [isRefreshing, setRefreshing] = useState(false);
  const [password, setPassword] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [matchOdds, setMatchOdds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [matchList, setMatchList] = useState([]);
  const [unresolvedMatches, setUnresolvedMatches] = useState([]);
  const [valueList, setValueList] = useState([]);
  const [resultLoading, setResultLoading] = useState("");
  const [matchLoading, setMatchLoading] = useState("");
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const settleMatch = async (sid, matchId) => {
    const winnerSid = document.getElementById(matchId).value;
    setMatchLoading(matchId);

    if (winnerSid) {
      const response = await axios.post(
        `${api_url}/bet/settleMatchBet`,
        { sid, matchId, winnerSid },
        config
      );
      toast.success(response.data.msg);
    }
    setMatchLoading("");
  };
  const settleBet = async (fancyName) => {
    setResultLoading(fancyName);
    const val = valueList.filter((x) => x.fancyName === fancyName);
    if (val.length) {
      try {
        const response = await axios.post(
          `${api_url}/bet/settleBet`,
          { fancyName, value: val[0].value, matchId: val[0].matchId },
          config
        );
        toast.success(response.data.msg);
        setResultLoading("");
      } catch (error) {
        console.error(error);
      }
    }
  };
  const resultValue = (value, fancyName, matchId) => {
    const data = { value, fancyName, matchId };
    setValueList([...valueList.filter((x) => x.fancyName !== fancyName), data]);
  };
  const getSessionOdds = async (socket) => {
    try {
      socket.on("allBets", (data) => {
        if (data) {
          setMatchOdds(data);
        }
      });
    } catch (error) {
      console.log(error);
    }
    socket.on("error", (message) => {
      setMatchOdds([]);
    });
    setLoading(false);
  };
  const getUnsettledMatch = async (socket) => {
    try {
      socket.on("unsettledMatches", (data) => {
        if (data) {
          setUnresolvedMatches(data);
        }
      });
    } catch (error) {
      console.log(error);
    }
    socket.on("error", (message) => {
      console.log(message);
      setMatchOdds({});
    });
    socket.emit("getUnsettledMatch");
    setLoading(false);
  };
  async function restartServer() {
    try {
      setPassword("");
      setShowModal(false);
      const response = await axios.post(
        `${api_url}/api/restartServer`,
        { password },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authContext.token}`,
          },
        }
      );
      if (response.data.msg) {
        toast.error(response.data.msg);
        setShowModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function getMatchData() {
    try {
      const response = await axios.get(`${api_url}/sports/getMatchList`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authContext.token}`,
        },
      });
      setMatchList(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getMatchData();
  }, []);

  useEffect(() => {
    const socket = socketIOClient(ENDPOINT);
    getSessionOdds(socket);
    getUnsettledMatch(socket);
    socket.emit("getAllBets");
    const interval = setInterval(() => {
      socket.emit("getAllBets");
    }, 1000);
    return () => {
      socket.disconnect();
      clearInterval(interval);
    };
  }, []);

  if (loading) {
    return (
      <div className="body-content no-padding">
        <Spinner color="#555" />
      </div>
    );
  }
  return (
    <>
      {showModal ? (
        <div className="modal-body">
          <div className="modal-content">
            <div className="modal-header">Enter Password</div>
            <div className="modal-c" style={{ backgroundColor: "#fff" }}>
              <div>
                <input
                  autoComplete="off"
                  type="text"
                  placeholder="Password"
                  id="amount"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="modal-btn">
              <button
                className="warn-btn"
                onClick={() => {
                  restartServer();
                }}
              >
                Restart
              </button>
              <button onClick={() => setShowModal(false)}>Cancel</button>
            </div>
          </div>
        </div>
      ) : null}
      <div className="body-content no-padding">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />

        <button className="warn-btn" onClick={() => setShowModal(true)}>
          Restart Server
        </button>
        <h3
          style={{
            padding: 10,
            color: "#555",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>Unresolved Matches</span>
          <span
            onClick={() => {
              setRefreshing(true);
              window.location.reload();
            }}
            className={isRefreshing ? "rotating" : ""}
          >
            <ReplayIcon style={{ fontSize: 30 }} />
          </span>
        </h3>
        <div style={{ paddingLeft: 10, paddingRight: 10 }}>
          {unresolvedMatches
            .sort((a, b) => new Date(b.time) - new Date(a.time))
            .map((match) => {
              const date = new Date(match.time);
              return (
                <div key={match._id} className="main-btn">
                  <div className="usmb">
                    <span
                      style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                    >
                      {match.eventName}{" "}
                      <b>{date.getDate() + " " + months[date.getMonth()]}: </b>
                    </span>
                    <select id={match.eventId}>
                      <option value="">Select</option>
                      {match.markets[0].runners.map((x) => {
                        return (
                          <option value={x.selectionId} key={x.selectionId}>
                            {x.selectionName}
                          </option>
                        );
                      })}
                      <option value="matchTied">Match Tied</option>
                      <option value="noResult">No Result</option>
                    </select>
                  </div>
                  <button
                    type="button"
                    onClick={() =>
                      matchLoading === match.eventId
                        ? console.log("Loading")
                        : settleMatch(match.marketId, match.eventId)
                    }
                  >
                    {matchLoading === match.eventId ? (
                      <Spinner color="#fff" />
                    ) : (
                      "Done"
                    )}
                  </button>
                </div>
              );
            })}
        </div>
        {matchList.map((match) => {
          const matchName = match.eventName;
          const data = matchOdds.filter((x) => x.matchId === match.eventId);
          return (
            data.length > 0 && (
              <div
                className="pr-box"
                key={match.eventId}
                style={{ paddingBottom: 80 }}
              >
                <div className="prbox-header">{matchName}</div>
                <div className="prbox-body odds-table">
                  <table>
                    <thead>
                      <tr>
                        <th>SESSION</th>
                        <th colSpan={2}>Result</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((odd) => {
                        return (
                          <tr key={odd.fancyName}>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span
                                  style={{
                                    width: "100%",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {odd.fancyName}
                                </span>
                                <span>:{odd.runs}</span>
                              </div>
                            </td>
                            <td>
                              <div className="table">
                                <input
                                  type="number"
                                  placeholder="Enter Result Here"
                                  style={{ padding: "8px 12px" }}
                                  onChange={(e) =>
                                    resultValue(
                                      e.target.value,
                                      odd.fancyName,
                                      odd.matchId
                                    )
                                  }
                                />
                              </div>
                            </td>
                            <td>
                              <div
                                className="table-flex"
                                style={{
                                  justifyContent: "center",
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                <button
                                  onClick={() =>
                                    resultLoading === odd.fancyName
                                      ? () => {}
                                      : resultLoading.length === 0
                                      ? settleBet(odd.fancyName)
                                      : () => {}
                                  }
                                  disabled={
                                    (resultLoading.length > 0 &&
                                      resultLoading !== odd.fancyName) ||
                                    resultLoading !== ""
                                  }
                                  style={{
                                    border: "none",
                                    padding: "8px 12px",
                                  }}
                                >
                                  {resultLoading === odd.fancyName ? (
                                    <Spinner
                                      color="#fff"
                                      style={{
                                        height: 13,
                                        width: 13,
                                        borderWidth: 2,
                                      }}
                                    />
                                  ) : (
                                    "Lock"
                                  )}
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div></div>
                </div>
              </div>
            )
          );
        })}
      </div>
    </>
  );
}

export default AllOdds;
