import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBciL_up6IJw5JfuSq5MKbGcC9E149Uruk",
  authDomain: "fly-bet.firebaseapp.com",
  projectId: "fly-bet",
  storageBucket: "fly-bet.appspot.com",
  messagingSenderId: "234722989736",
  appId: "1:234722989736:web:e225a0837f066f6876d49d",
  measurementId: "G-8TKVKGZ6JJ",
};
export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
