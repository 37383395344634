import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import "./style/app.css";
import { useEffect, useContext, useState } from "react";
import "./style/adminhome.css";
import { AuthProvider, AuthContext } from "./context/AuthContext";
import { useCallback } from "react";
import Main from "./components/Main";
import { messaging } from "./config/firebase";
import { getToken } from "firebase/messaging";
import axios from "axios";
import api_url from "./constants";

function App() {
  const [userContext, setUserContext] = useContext(AuthContext);
  const [permission, setPermission] = useState(Notification.permission);
  const config = {
    headers: { Authorization: `Bearer ${userContext.token}` },
  };
  const syncLogout = useCallback((event) => {
    if (event.key === "managerLogout") {
      window.location.reload();
    }
  }, []);
  const requestNotificationPermission = async () => {
    const permission = await Notification.requestPermission();
    setPermission(permission);
    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey:
          "BAQk8VDP8ia6cHlieSuof0mxiyfp1bH3wtF4NXBpls8PwucYASq7Wkk_pvD6gvuW7_u03AXPtxcKy9J6ar3cJfE",
      });
      await axios.post(`${api_url}/api/saveToken`, { token }, config);
    } else if (permission === "denied") {
      alert("Please allow notification to get notified");
    }
  };

  useEffect(() => {
    window.addEventListener("storage", syncLogout);
    return () => {
      window.removeEventListener("storage", syncLogout);
    };
  }, [syncLogout]);

  return (
    <div className="app">
      {userContext.token ? (
        <Main
          userContext={userContext}
          setUserContext={setUserContext}
          requestNotificationPermission={requestNotificationPermission}
          permission={permission}
        />
      ) : (
        <div>
          <header></header>
          <div className="content">
            <Routes>
              <Route exact path="/" index element={<Login />} />
            </Routes>
          </div>
        </div>
      )}
    </div>
  );
}

const AppWrapper = () => (
  <AuthProvider>
    <Router>
      <App />
    </Router>
  </AuthProvider>
);
export default AppWrapper;
