import React, { useState, useEffect, useContext } from "react";
import "../style/odds.css";
import axios from "axios";
import Spinner from "./Spinner";
import { useLayoutEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api_url from "../constants";
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

function Toss() {
  const [authContext, setAuthContext] = useContext(AuthContext);
  const config = {
    headers: { Authorization: `Bearer ${authContext.token}` },
  };
  const [loading, setLoading] = useState(true);
  const [unresolvedMatches, setUnresolvedMatches] = useState([]);
  const [matchLoading, setMatchLoading] = useState("");
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const settleMatch = async (matchId) => {
    const winnerSid = document.getElementById(matchId).value;
    setMatchLoading(matchId);
    if (winnerSid) {
      const response = await axios.post(
        `${api_url}/bet/settleTossBet`,
        { matchId, winnerSid },
        config
      );
      toast.success(response.data.msg);
      await getUnsettledToss();
    }
    setMatchLoading("");
  };

  const getUnsettledToss = async () => {
    try {
      const response = await axios.get(
        `${api_url}/bet/getUnsettledToss`,
        config
      );
      setUnresolvedMatches(response.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getUnsettledToss();
    return () => {};
  }, []);

  if (loading) {
    return (
      <div className="body-content no-padding">
        <Spinner color="#555" />
      </div>
    );
  }
  return (
    <div className="body-content no-padding">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <h3 style={{ padding: 10, color: "#555" }}>Unresolved Toss</h3>
      <div style={{ paddingLeft: 10, paddingRight: 10 }}>
        {unresolvedMatches
          .sort((a, b) => new Date(b.time) - new Date(a.time))
          .map((match) => {
            const date = new Date(match.time);
            return (
              <div key={match.matchId} className="main-btn">
                <div className="usmb">
                  <span
                    style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                  >
                    {match.matchname}{" "}
                    <b>{date.getDate() + " " + months[date.getMonth()]}: </b>
                  </span>
                  <select id={match.matchId}>
                    <option value="">Select</option>
                    {match.runnerArray.map((x) => {
                      return (
                        <option value={x.selectionId} key={x.selectionId}>
                          {x.selectionName}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <button
                  type="button"
                  onClick={() =>
                    matchLoading === match.matchId
                      ? console.log("Loading")
                      : settleMatch(match.matchId)
                  }
                >
                  {matchLoading === match.matchId ? (
                    <Spinner color="#fff" />
                  ) : (
                    "Done"
                  )}
                </button>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default Toss;
