import React, { useState } from "react";
import "../style/inPlay.css";
import { Link } from "react-router-dom";

function InPlay() {
  String.prototype.allReplace = function (obj) {
    var retStr = this;
    for (var x in obj) {
      retStr = retStr.replace(new RegExp(x, "g"), obj[x]);
    }
    return retStr;
  };

  return (
    <div>
      <div className="body-content">
        <div id="tabs">
          <div id="tabs-1">
            <div className="cnt-tab">
              <Link to={`/allOdds`} className="c-card">
                <div className="c-date">
                  <div className="c-month">FOREVER</div>
                </div>
                <div className="c-main">
                  <p className="card-heading">ALL MATCHES BETS</p>
                  <p className="mt-7 card-text"></p>
                </div>
              </Link>
            </div>
            <div className="cnt-tab">
              <Link to={`/toss`} className="c-card">
                <div className="c-date">
                  <div className="c-month">FOREVER</div>
                </div>
                <div className="c-main">
                  <p className="card-heading">UNSETTLED TOSS</p>
                  <p className="mt-7 card-text"></p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InPlay;
