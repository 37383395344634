import React, { useState, useCallback, useEffect } from "react";
import { Route, Routes, Link } from "react-router-dom";
import InPlay from "./InPlay";
import AllOdds from "./AllOdds";
import Spinner from "./Spinner";
import axios from "axios";
import api_url from "../constants";
import Toss from "./Toss";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import Revert from "./Revert";

function Main(props) {
  const { userContext, setUserContext } = props;
  const [dataLoading, setDataLoading] = useState(true);

  const fetchUserDetails = useCallback(async () => {
    try {
      const response = await axios.get(`${api_url}/auth/me`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userContext.token}`,
        },
        withCredentials: true,
        validateStatus: function (status) {
          return status >= 200; // Treat any status code in the 2xx range as a success
        },
      });

      if (response.status === 200) {
        const data = response.data;
        setUserContext((oldValues) => {
          return { ...oldValues, details: data };
        });
      } else {
        setUserContext((oldValues) => {
          return { ...oldValues, details: null, token: null };
        });

        // Handle non-200 status codes here if needed
        // For example, you can show an error message to the user
      }
    } catch (error) {
      // Handle network or other errors here
      console.error("An error occurred:", error);
    } finally {
      setDataLoading(false);
    }
  }, [setUserContext, userContext.token]);
  useEffect(() => {
    fetchUserDetails();
  }, [userContext.token]);

  if (dataLoading) {
    return (
      <div style={{ paddingTop: 30 }}>
        <Spinner color="#222" />
      </div>
    );
  }
  return (
    <div>
      <div>
        <header>
          <div className="right-menu">
            <div style={{ display: "flex", paddingLeft: 10 }}>
              <div>
                <span className="c-logo">
                  <img src={require("../images/logo192.png")} alt="logo" />
                </span>
              </div>
              <div
                className="c-flex"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center ",
                }}
              >
                <div className="one-line">
                  <span style={{ textTransform: "uppercase" }}>
                    {userContext.details.username}
                  </span>{" "}
                </div>
                {props.permission === "granted" ? (
                  <NotificationsOffIcon
                    style={{ fontSize: 30 }}
                    onClick={() => props.requestNotificationPermission()}
                  />
                ) : (
                  <NotificationsIcon
                    style={{ fontSize: 30 }}
                    onClick={() => props.requestNotificationPermission()}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="flex__align">
            <Link
              to="/revert"
              style={{ color: "#fff", fontSize: 16, paddingRight: 10 }}
            >
              REVERT
            </Link>
            <Link
              to="/"
              style={{ color: "#fff", fontSize: 16, paddingRight: 10 }}
            >
              HOME
            </Link>
          </div>
        </header>
      </div>
      <div className={`content-cnt no-sidebar`}>
        <Routes>
          <Route exact path="/" index element={<InPlay />} />
          <Route exact path="/allOdds" index element={<AllOdds />} />
          <Route exact path="/toss" index element={<Toss />} />
          <Route exact path="/revert" index element={<Revert />} />
        </Routes>
      </div>
    </div>
  );
}

export default Main;
