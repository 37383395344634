import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../style/login.css";
import "../style/register.css";
import Spinner from "./Spinner";
import api_url from "../constants";
import axios from "axios";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
function Login() {
  const [userContext, setUserContext] = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [err, setError] = useState("");
  const navigate = useNavigate();
  const login = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    try {
      const response = await axios.post(
        `${api_url}/auth/managerLogin`,
        {
          username,
          password,
        },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.success) {
        const data = await response.data;
        setUserContext((oldValues) => {
          return { ...oldValues, token: data.token };
        });
      } else {
        setError(response.data.err);
      }
    } catch (error) {
      setError(
        error.response.data.err ? error.response.data.err : "Incorrect Password"
      );
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div style={{ flex: 1 }}>
        <Spinner color="#000" />
      </div>
    );
  }
  return (
    <div className="login">
      <form className="form-container" onSubmit={login}>
        <div className="form-header">Login</div>
        <div className="login__container">
          {err ? <div className="error-message">{err}</div> : ""}

          <div className="row-input">
            <label>Username</label>
            <input
              type="text"
              className="input__textBox"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Username"
            />
          </div>
          <div className="row-input">
            <label>PASSWORD</label>
            <input
              type="password"
              className="input__textBox"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
          </div>
          <button className="main__btn" type="submit">
            {isLoading ? <Spinner /> : "LOGIN"}
          </button>
        </div>
        <div>
          {/* <div className="login-button link">
            <Link to="/register">Register</Link>
          </div> */}
        </div>
      </form>
    </div>
  );
}
export default Login;
